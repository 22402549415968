import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { ImageGrid } from "../components/ImageGrid"
import { Hero } from "../components/Hero"
import { Image } from "../components/Image"

const poolConceptImage = number => `poolConcept-${number}.jpg`

const PoolConceptPage = () => {
  return (
    <Layout isProjectPage>
      <SEO />
      <Hero>
        Pool Concept
        <Image src={poolConceptImage(7)} />
      </Hero>

      {[...Array(7)].map((image, index) => (
        <Image
          style={{ margin: "80px 0px" }}
          src={poolConceptImage(index + 1)}
        />
      ))}
    </Layout>
  )
}

export default PoolConceptPage
